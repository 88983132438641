<template>
  <v-app>
    <SingleAppBar :title="$t('patient.title')" :backward="true" />
    <v-main class="natural lighten-4 fill-height">
      <v-container fluid>
        <v-row v-if="patient">
          <v-col cols="12">
            <v-card>
                <v-card-text class="text-center">
                <v-avatar size="104">
                      <v-img
                        ref="imgSrc"
                        :src="
                          imgSrc || require('@/assets/whiteavatar.svg')
                        "
                      >
                      </v-img>
                    </v-avatar>
                  <!-- style="display: none" -->

                  <div class="text-left">
               <label class="text-title-4 primary--text"
                      >{{$t('patient.fullname')}}</label>
                      <div class="text-h6 natural--text text--darken-4">{{patient.full_name}}</div>
                      <!-- <v-text-field
                        outlined
                        v-model="patient.full_name"
                      >
                      </v-text-field> -->
               
                  </div>
                </v-card-text>
              </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-title-4 primary--text pb-1">{{$t('patient.personalInfo')}}</v-card-title>
              <v-card-text>
                   <v-list class="pt-0">
                       <v-list-item>
                      <v-list-item-icon>
                        <v-icon  v-text="'mdi-clock-time-eight-outline'"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-large"
                          v-text="$t('patient.age') + patient.age_text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="patient.phone_mobile">
                      <v-list-item-icon>
                        <v-icon  v-text="'mdi-phone'"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-large"
                          v-text="patient.phone_mobile"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                       <v-list-item v-if="patient.email_1">
                      <v-list-item-icon>
                        <v-icon  v-text="'mdi-mail'"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-large"
                          v-text="patient.email_1"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                  </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-title-4 primary--text pb-1"
                >{{$t('patient.allergy')}}</v-card-title
              >
              <v-card-text v-if="!patient.allergy">
                {{$t('patient.empty')}}
              </v-card-text>
              <v-card-text v-else>
                <v-list v-if="Array.isArray(patient.allergy)">
                  <v-list-item v-for="(item,index) in patient.allergy" :key="index">
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap">{{item.alg_name}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>{{$t('patient.informationDate')}}</v-list-item-action-text>
                      <v-list-item-action-text>{{ $dayjs(item.last_update).local($i18n.locale).format('DD MMMM YYYY')}}</v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-list v-else>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap">{{patient.allergy}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
              </v-card
            >
          </v-col>
            <v-col cols="12">
            <v-card>
              <v-card-title class="text-title-4 primary--text pb-1"
                > {{$t('patient.underlayingDisease')}}</v-card-title
              >
   <v-card-text v-if="!patient.underlying_list">
    {{$t('patient.empty')}}
              </v-card-text>
              <v-card-text v-else>
                <v-list>
                  <v-list-item v-for="(item,index) in patient.underlying_list" :key="index">
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap">{{item.description_th}}</v-list-item-title>
                    </v-list-item-content>
                     <v-list-item-action>
                      <v-list-item-action-text>{{$t('patient.informationDate')}}</v-list-item-action-text>
                     <v-list-item-action-text>{{ $dayjs(item.last_update).local($i18n.locale).format('DD MMMM YYYY')}}</v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
              </v-card
            >
          </v-col>
            <v-col cols="12">
            <v-card>
              <v-card-title class="text-title-4 primary--text pb-1"
                >{{$t('patient.currentMedication')}}</v-card-title
              >
<v-card-text v-if="!patient.current_med">
  {{$t('patient.empty')}}
              </v-card-text>
              <v-card-text v-else>
                   <v-list>
                  <v-list-item v-for="(item,index) in patient.current_med" :key="index">
                    <v-list-item-content>

                      <v-list-item-title class="text-wrap">{{item.Generic_name}}</v-list-item-title>
                      <template v-if="$i18n.locale === 'th'">
                        <v-list-item-subtitle class="text-wrap" >{{item.instruction_text}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-wrap">{{item.using_code2}}</v-list-item-subtitle>
                      </template>
                      <template v-else>
                        <v-list-item-subtitle class="text-wrap">{{item.instruction_eng}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-wrap">{{item.instruction_jap}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-wrap">{{item.info_eng}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-wrap">{{item.info_jap}}</v-list-item-subtitle>
                      </template>
                    </v-list-item-content>
                     <v-list-item-action>
                      <!-- <v-list-item-action-text>{{$t('patient.informationDate')}}</v-list-item-action-text>
                     <v-list-item-action-text>{{ $dayjs(item.verify_datetime).locale($i18n.locale).format('DD MMMM YYYY')}}</v-list-item-action-text> -->
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text></v-card
            >
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-title-4 primary--text pb-1"
                > {{$t('patient.vaccine')}}</v-card-title
              >

<v-card-text v-if="!patient.curr_med_note">
  {{$t('patient.empty')}}
              </v-card-text>
              <v-card-text v-else>
                 <v-list>
                  <v-list-item v-for="(item,index) in patient.curr_med_note" :key="index">
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap">{{item.vaccine_name}}</v-list-item-title>
                       <v-list-item-subtitle class="text-wrap">{{item.vaccine_text}}</v-list-item-subtitle>
                    </v-list-item-content>
                     <v-list-item-action>
                      <v-list-item-action-text>{{$t('patient.informationDate')}}</v-list-item-action-text>
                     <v-list-item-action-text>{{ $dayjs(item.vaccine_date).local($i18n.locale).format('DD MMMM YYYY')}}</v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>

              
              </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import SingleAppBar from "@/components/common/SingleAppBar";
import { cmsPatientPicture } from "@/api/";
import { getPatients } from "@/api/cms"
//import FriendList from "@/components/addfriend/FriendList"
export default {
  name: "Patient",
  components: {
    SingleAppBar,
    //FriendList
  },
  data() {
    return {
      patient: null,
      loading: false,
      imgSrc: null
    };
  },
  methods: {
    async fetchData() {
      try {
        const site = this.$offlineStorage.get("site");
        this.loading = true;
        // let patient = await getCMSPatient({
        //   groupId: site.groupId,
        //   keyword: site.profile.patient_id,
        // });
        let patient = await getPatients()
        if (patient.data) {
          this.patient = patient.data;
          if(this.patient.pat_img) {
            let picture = await cmsPatientPicture({groupId: site.groupId,profilePicture: this.patient.pat_img})
          if(picture.data.code === 1) {
            this.imgSrc = picture.data.result
          }
          }
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
</style>